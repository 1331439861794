import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Button from "../components/UI-Elements/Button";
import { useForm } from "react-hook-form";
import { navigate } from "gatsby";
import { encode } from "../common/helpers/utils";
import Input from "../components/UI-Elements/Form/Input";
import Textarea from "../components/UI-Elements/Form/Textarea";
import Title from "@components/UI-Elements/Typography/Title";

const ContactPage: React.FC = () => {
  const { register, handleSubmit } = useForm();
  const onSubmit = (data: any) => {
    console.log(data);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...data,
      }),
    })
      .then(() => navigate("/"))
      .catch((error) => alert(error));
  };

  return (
    <Layout>
      <SEO keywords={["contact"]} title="Kontakt" />
      <Title>Kontakt</Title>
      <section>
        <form name="contact" data-netlify="true" onSubmit={handleSubmit(onSubmit)}>
          <Input label="Deine Name" id="name" placeholder="Satoshi Nakamoto" required {...register("name", { required: true })} />
          <Input
            label="Deine E-Mail"
            id="email"
            placeholder="satoshi@bitcoin.de"
            type="email"
            required
            {...register("firstName", { required: true })}
          />
          <Textarea
            label="Deine Nachricht"
            id="message"
            placeholder="Was liegt dir auf der Seele?"
            rows={8}
            required
            {...register("message", { required: true })}
          />

          <Button type="submit">Abschicken</Button>
        </form>
      </section>
    </Layout>
  );
};

export default ContactPage;
